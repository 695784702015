<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Forgot Password" :showBack="true"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">Forget Password</div>
            <div class="form-item p-b-0" v-if="type == 2">
              <label>Mobile number</label>
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <input type="tel" v-model="$store.state.userInfo.phone" disabled placeholder="Mobile number" />
                </div>
              </div>
              <div class="continueWithEmail Medium">Enter the mobile number you registered with. A code will be sent if an account exists for this mobile number.</div>
            </div>
            <div class="form-item p-b-0" v-else>
              <label>Email </label>
              <div class="input-box">
                <input type="text" v-model="$store.state.userInfo.email" disabled placeholder="Email Address" @input="$store.state.userInfo.email = $store.state.userInfo.email.trim().toLowerCase()" />
              </div>
              <div class="continueWithEmail Medium">Enter the email you registered with. A code will be sent if an account exists for this email.</div>
            </div>
            <div class="pad-t-50">
              <ion-button expand="block" strong @click="confirmEvent">Continue</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "forgetPassword",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton },
  data() {
    return {
      type: true,
      canSendS: true,
      canSendE: true,
    };
  },
  methods: {
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 4;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 2;
      this.$router.push({
        path: "/verifyCode/4/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      this.$store.state.userInfo.email = this.$store.state.userInfo.email.trim().toLowerCase();
      objdata.email = this.$store.state.userInfo.email;
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 4;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 1;
      this.$router.push({
        path: "/verifyCode/4/1",
      });
    },
    confirmEvent() {
      if (this.type == 2) {
        this.sendGAEvent("Click", "Edit profile", "Phone");

        this.sendSmsEvent();
      } else {
        this.sendGAEvent("Click", "Edit profile", "Email");

        this.sendEmailEvent();
      }
    },
  },
  ionViewWillEnter() {
    this.type = this.$route.params.type;
    if (this.$store.state.form.token) {
      console.log(this.$store.state.form);
      if (this.$store.state.form.userInfo.isPhoneValidation > 0) {
        this.$store.state.userInfo.phone = this.$store.state.form.userInfo.phone;
      } else if (this.$store.state.form.userInfo.isEmailValidation > 0) {
        this.$store.state.userInfo.email = this.$store.state.form.userInfo.email.trim().toLowerCase();
      }
    }
    this.$store.state.exitApp = false;
    this.sendGAPageView("Forget Password");
  },
};
</script>
